import { render, staticRenderFns } from "./advertisingList.vue?vue&type=template&id=2c2abfe8&scoped=true&"
import script from "./advertisingList.vue?vue&type=script&lang=js&"
export * from "./advertisingList.vue?vue&type=script&lang=js&"
import style0 from "./advertisingList.vue?vue&type=style&index=0&id=2c2abfe8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c2abfe8",
  null
  
)

export default component.exports