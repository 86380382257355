<template>
  <!-- 广告列表 -->
  <div>
    <div class="flex">
      <!-- <div class="t-flex">
        <span>显示终端</span>
        <el-select v-model="form" placeholder="请选择广告点击跳转类型">
          <el-option label="PC首页" value="1"></el-option>
          <el-option label="手机设备" value="2"></el-option>
        </el-select>
        <div>
          <el-button size="medium" @click="resetForm">重置</el-button>
          <el-button size="medium" type="primary">查询</el-button>
        </div>
      </div> -->
      <el-button size="medium" type="danger" @click="newNews">新增</el-button>
    </div>
    <!-- 中间表格 -->
    <div>
      <el-table :data="data" border>
        <el-table-column label="序号" prop="sort"></el-table-column>
        <el-table-column label="广告描述" prop="describes"></el-table-column>
        <el-table-column label="跳转类型" prop="createBy"></el-table-column>
        <el-table-column
          label="跳转地址"
          prop="image"
          width="300"
        ></el-table-column>
        <!-- <el-table-column
          label="显示终端"
          prop="displayTerminal"
        ></el-table-column> -->
        <el-table-column label="状态" prop="sort">
          <template slot-scope="scope">
            {{ scope.row.store ? "审核通过" : "未审核" }}
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="adminTel"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="operation">
              <div @click="chaKang(scope.row.id, scope.$index)">查看</div>
              <div @click="deletet(scope.row.id, scope.$index)">修改</div>
              <div @click="handleDelete(scope.row.id, scope.$index)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 底部分页
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[1, 2, 3, 4, 5]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: "",
      data: [],
      pageSize: 10,
      currentPage: 4,
      totalItems: 0,
    };
  },
  created() {
    // 获取后台广告数据
    this.onAdvert();
  },
  methods: {
    // 获取后台广告数据
    onAdvert() {
      this.axios
        .get("/admin/indexImage/findAll", {})
        .then((res) => {
          this.data = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //   重置
    resetForm() {
      this.form = "";
    },
    // 查看
    chaKang(id, index) {
      this.$router.push({
        path: "newAdvertisee",
        query: { data: this.data[index] },
      });
    },
    // 修改
    deletet(id, index) {
      this.$router.push({
        path: "newAdvertisccc",
        query: { data: this.data[index] },
      });
    },
    // 删除
    handleDelete(id, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .delete(`/admin/indexImage/delIndexImage?ids=${id}`, {})
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.data.splice(index, 1);
              } else {
                this.$message({
                  type: "error",
                  message: "删除失败！",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      this.onAdvert();
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // 跳转新闻分类新增
    newNews() {
      // alert("新增新闻分类");
      this.$router.push("newAdvertis");
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    span {
      width: 80px;
    }
    div {
      margin: 0 10px;
    }
  }
}
.pagination {
  padding: 60px 0 0 100px;
}
.t-flex {
  width: 600px;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>
